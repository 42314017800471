// Support IE11: make Promise (in Angular, Webpack runtime), Object.assign ...
// Need import on top of all imports
import 'babel-polyfill';
import 'styles/less/app.less';
// hack
// TODO: move pp-style import from https://assets.parcelperform.com/assets/css/pp-styles.css in index.html
// to improve from pp-styles package
import 'global.scss';

import appEnvironmentService from '@pperform/pp-services/AppEnvironmentService';

(async function () {
  const { token_type, access_token } = JSON.parse(
    localStorage.getItem('currentUserInfo'),
  );
  await appEnvironmentService.init({
    url: 'pp-portal-api/v1/conf',
    token: `${token_type} ${access_token}`,
  });

  await import('./appStateManager');
  await import('app/es6');

  angular.bootstrap(window.document, ['pp_analytics']);
})();
